<template>
	<div class="main-group-statistic">
		<el-row :gutter="20">
			<el-col :span="8">
				<div>
					<div class="title-box"><span class="m-title">SIM卡功能状态</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="pirMainId" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00b395;"></div>
										<div class="inline ml10">
											<span class="tip-text1">正常</span><br />
											<span class="tip-text2">{{simData.usedNum}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00c6c7;"></div>
										<div class="inline ml10">
											<span class="tip-text1">停机/断网</span><br />
											<span class="tip-text2">{{simData.downNum}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #c0c9cd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">其他</span><br />
											<span class="tip-text2">{{simData.othter}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
						
						
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div>
					<div class="title-box"><span class="m-title">即将到期卡</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="pirMainId2" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #fe941a;"></div>
										<div class="inline ml10">
											<span class="tip-text1">一个月</span><br />
											<span class="tip-text2">{{simData.oneMonth}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #fe551a;"></div>
										<div class="inline ml10">
											<span class="tip-text1">二个月</span><br />
											<span class="tip-text2">{{simData.twoMonth}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #c0c9cd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">三个月</span><br />
											<span class="tip-text2">{{simData.threeMonth}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div>
					<div class="title-box"><span class="m-title">SIM卡业务状态</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="liuliang_pie" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #97c407;"></div>
										<div class="inline ml10">
											<span class="tip-text1">正在使用</span><br />
											<span class="tip-text2">{{simData.isUsedNum}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #3cc407;"></div>
										<div class="inline ml10">
											<span class="tip-text1">测试期</span><br />
											<span class="tip-text2">0</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #c0c9cd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">沉默期</span><br />
											<span class="tip-text2">{{simData.unUsedNum}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			
		</el-row>
		<el-row :gutter="20" class="mt20">
			<el-col :span="16">
				<div class="title-box"><span class="m-title">本月GPRS流量（GB/天）</span></div>
				<div class="content-box" style="padding-left: 0;padding-right: 0;">
					<div id="chartFlow" v-if="hasChartFlowData" style="height: 420px;"></div>  
					<div v-else class="no-data" style="height: 420px;"><span>暂无数据</span></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="title-box"><span class="m-title">本月流量消耗TOP10</span></div>
				<div class="content-box">
					<el-table ref='table'  :data="flowTableData" height="420px">
						<el-table-column width="80" prop="number" label="排名"></el-table-column>
						<el-table-column prop="iccidMark" label="SIM卡号">
							<template slot-scope="scope">
								<a href='javascript:;' @click="getDetail(scope.row.iccidMark)" style="color: #337ab7;">{{scope.row.iccidMark}}</a>
							</template>
						</el-table-column>
						<el-table-column width="140" prop="flowNum" label="使用流量(MB)"></el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="mt20">
			<el-col :span="16">
				<div class="title-box"><span class="m-title">本月上行短信</span></div>
				<div class="content-box">
					<div id="chartSMS" v-if="hasChartSMSData" style="height: 420px;"></div>
					<div v-else class="no-data" style="height: 420px;"><span>暂无数据</span></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="title-box"><span class="m-title">本月上行短信TOP10</span></div>
				<div class="content-box">
					<el-table ref='table'  :data="smsTableData" height="420px">
						<el-table-column prop="number" width="80" label="排名"></el-table-column>
						<el-table-column prop="iccidMark" label="SIM卡号">
							<template slot-scope="scope">
								<a href='javascript:;' @click="getDetail(scope.row.iccidMark)" style="color: #337ab7;">{{scope.row.iccidMark}}</a>
							</template>
						</el-table-column>
						<el-table-column prop="smsNum" width="140" label="短信上行(次)"></el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import echarts from 'echarts'
	import {listSimGroup,ListDayFlowGroup,ListDaySmsGroup,ListTopFlowGroup,ListTopSMSGroup}from '@/api/statistics.js'
	export default{
		name:'groupStatistic',
		data(){
			return {
				pieChart1:'',
				pieChart2:'',
				pieChart3:'',
				lineChart1:'',
				lineChart2:'',
				flowTableData:[],
				smsTableData:[],
				simData:{},
				hasChartFlowData:true,
				hasChartSMSData:true
			}
		},
		created() {
			this.groupId = this.$route.query.groupId
			this.getListSim()
			this.getListDayFlow()
			this.getListDaySMS()
			this.getListTopFlow()
			this.getListTopSMS()
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					this.pieChart1.resize()
					this.pieChart2.resize()
					this.pieChart3.resize()
					if (!!this.lineChart1) {
						this.lineChart1.resize()
					}
					if (!!this.lineChart2) {
						this.lineChart2.resize()
					}
					
				})()
			}
			
		},
		methods:{
			getDetail(iccidMark){
				this.$router.push({path:'/card/detail',query:{iccidMark:iccidMark}})
			},
			getListSim(){
				listSimGroup({groupId:this.groupId}).then(res=>{
					
					res.isUsedNum = res.isUsedNum == null ? 0 :res.isUsedNum
					res.unUsedNum = res.unUsedNum == null ? 0 :res.unUsedNum
					this.simData = res
					this.loadPie1(this.simData.usedNum,this.simData.downNum,this.simData.othter)
					this.loadPie2(this.simData.oneMonth,this.simData.twoMonth,this.simData.threeMonth)
					this.loadPie3(this.simData.isUsedNum,0,this.simData.unUsedNum)
					
				})
			},
			getListDayFlow(){
				ListDayFlowGroup({groupId:this.groupId}).then(res=>{
					if (!!res && res.length > 0) {
						this.hasChartFlowData = true
						this.loadChartFlow(res)
					}else{
						this.hasChartFlowData = false
					}
				})
			},
			getListDaySMS(){
				ListDaySmsGroup({groupId:this.groupId}).then(res=>{
					if (!!res && res.length > 0) {
						this.hasChartSMSData = true
						this.loadChartSMS(res)
					}else{
						this.hasChartSMSData = false
					}
				})
			},
			getListTopFlow(){
				ListTopFlowGroup({groupId:this.groupId}).then(res=>{
					this.flowTableData = res.map((item,index)=>{
						item.number = index + 1
						return item
					})
					
				})
			},
			getListTopSMS(){
				ListTopSMSGroup({groupId:this.groupId}).then(res=>{
					this.smsTableData = res.map((item,index)=>{
						item.number = index + 1
						return item
					})
				})
			},
			loadPie1(nomarlCount ,disableCount,overflowCount){
				var myChart = echarts.init(document.getElementById('pirMainId'));
				this.pieChart1 = myChart
				const option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show:false
					},
					series: [
						{
							color:["#00b395" ,"#00c6c7","#c0c9cd"],
							name:'网卡使用统计',
							type:'pie',
							radius: ['50%', '75%'],
							avoidLabelOverlap: false,
							label: {
								normal: {
									show: false,
									position: 'center'
								},
								emphasis: {
									show: true,
									textStyle: {
										fontSize: '14',
										fontWeight: 'bold'
									}
								}
							},
							labelLine: {
								normal: {
									show: false
								}
							},
							data:[
								{value:nomarlCount, name:'正常'},
								{value:disableCount, name:'停机/断网'},
								{value:overflowCount, name:'其他'},
							]
						}
					]
				};
				myChart.setOption(option);
				
			},
			loadPie2(dist, undist, stop){
				
				var myChart = echarts.init(document.getElementById('pirMainId2'));
				this.pieChart2 = myChart
				let option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show:false
					},
					series: [
						{
							color:[ "#fe941a","#fe551a","#c0c9cd"],
							name:'即将到期卡',
							type:'pie',
							radius: ['50%', '75%'],
							avoidLabelOverlap: false,
							label: {
								normal: {
									show: false,
									position: 'center'
								},
								emphasis: {
									show: true,
									textStyle: {
										fontSize: '14',
										fontWeight: 'bold'
									}
								}
							},
							labelLine: {
								normal: {
									show: false
								}
							},
							data:[
								{value:dist, name:'一个月'},
								{value:undist, name:'两个月'},
								{value:stop, name:'三个月'}
							]
						}
					]
				};
				myChart.setOption(option);
			
			},
			loadPie3(shengyu,yishiyong,no){
			
				var myChart = echarts.init(document.getElementById('liuliang_pie'));
				this.pieChart3 = myChart
				let option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show:false
					},
					series: [
						{
							color:["#97c407" ,"#3cc407","#c0c9cd" ],
							name:"SIM卡业务状态",
							type:'pie',
							radius: ['50%', '75%'],
							avoidLabelOverlap: false,
							label: {
								normal: {
									show: false,
									position: 'center'
								},
								emphasis: {
									show: true,
									textStyle: {
										fontSize: '14',
										fontWeight: 'bold'
									}
								}
							},
							labelLine: {
								normal: {
									show: false
								}
							},
							data:[
								{value:shengyu, name:'正在使用'},
								{value:yishiyong, name:'测试'},
								{value:no, name:'沉默'}
							]
						}
					]
				};
				myChart.setOption(option);
			},
			loadChartFlow(dataList){
				var titleArr = [],valueArr = [];
				for(var i=0;i<dataList.length;i++){
					var record = dataList[i];
					titleArr.push(record.dayNum);
					valueArr.push(record.flowNum);
				}
			
				var myChart = echarts.init(document.getElementById("chartFlow"));
				this.lineChart1 = myChart
				let option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: titleArr
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {label: {show: true}}
				
						},
						color: '#ff6b01', lineStyle: {
							color: '#A5B6E0'
						},
						data: valueArr,
						type: 'line',
						areaStyle: {}
					}]
				};
				myChart.setOption(option);
			
			},
			loadChartSMS(dataList){
				var titleArr = [],valueArr = [];
				for(var i=0;i<dataList.length;i++){
					var record = dataList[i];
					titleArr.push(record.dayNum);
					valueArr.push(record.smsNum);
				}
				var myChart = echarts.init(document.getElementById("chartSMS"));
				this.lineChart2 = myChart
				let option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: titleArr
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {label: {show: true}}
				
						},
						color: '#ff6b01', lineStyle: {
							color: '#A5B6E0'
						},
						data: valueArr,
						type: 'line',
						areaStyle: {}
					}]
				};
				myChart.setOption(option);
				
			}
		}
		
	}
</script>

<style>
	.tip-main{
		margin-top: 18px;
	}
	.tip-view{
		display: inline-block;
		width: 18px;
		height: 18px;
		vertical-align: top;
		margin-top: 5px;
	}
	.tip-text1{
		color: #676a6c;
	}
	.tip-text2{
		color: #ff6b01;
	}
	
	.main-group-statistic .el-table table{
		box-sizing: border-box !important;
		border-top: .5px solid #e7e7e7 !important;
		border-left: .5px solid #e7e7e7 !important;;
	}
	.main-group-statistic .el-table td,
    .main-group-statistic .el-table th.is-leaf {
		border-right: .5px solid #e7e7e7 !important;
		border-bottom: .5px solid #e7e7e7 !important;
		background-color: #F8F8F8;
	}
	.main-group-statistic .el-table--small td,
	.main-group-statistic .el-table--small th {
		padding: 6px 0 !important;
		color: #676a6c !important;
	}
</style>
